import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const SelectDay = loadable(() =>
    import('/src/components/Form/Specialised/SelectDay')
)
const SelectMonth = loadable(() =>
    import('/src/components/Form/Specialised/SelectMonth')
)
const SelectYear = loadable(() =>
    import('/src/components/Form/Specialised/SelectYear')
)
const TextField = loadable(() => import('/src/components/Form/TextField'))

const StepBeneficiaryDetails = () => {
    return (
        <>
            <h2 className="c-wizard__step-intro">Beneficiary Information</h2>

            <div className="c-wizard__form-step">
                <FormRow>
                    <TextField
                        label={"Your Beneficiary's full legal name"}
                        name={'beneficiaryName'}
                        placeholder={'e.g. Katie Jones'}
                        required
                    />
                </FormRow>

                <FormRow>
                    <FieldSet legend={"Beneficiary's date of birth*"}>
                        <FieldSpacer grow={'stretch'}>
                            <SelectDay name={'beneficiaryDobDay'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <SelectMonth name={'beneficiaryDobMonth'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <SelectYear
                                name={'beneficiaryDobYear'}
                                minAge={1}
                                maxAge={90}
                            />
                        </FieldSpacer>
                    </FieldSet>
                </FormRow>

                <FormRow>
                    <TextField
                        label={"First line of your Beneficiary's address"}
                        name={'beneficiaryAddress'}
                        placeholder={'e.g. 105 Dover Road'}
                    />
                </FormRow>

                <FormRow>
                    <TextField
                        label={"Beneficiary's postcode"}
                        name={'beneficiaryPostcode'}
                        placeholder={'e.g. ZE2 7UL'}
                    />
                </FormRow>
            </div>
        </>
    )
}

export default StepBeneficiaryDetails
